import React from "react"
import UnderConstruction from "../UnderConstruction/UnderConstruction"

const ContentHome = () => {
  return (
    <UnderConstruction />
  )
}

export default ContentHome
